// --------------------------------------------------
// Layout default
// --------------------------------------------------

// with default colors (currently, dark)
body {

  .date {
    font-size: rem-calc(18);
    font-style: italic;
    vertical-align: bottom;
  }

  blockquote {
    margin-left: rem-calc(13);
    p {
      text-align: justify;
      font-size: rem-calc(15) !important;
    }
    li {
      text-align: justify;
      font-size: rem-calc(15) !important;
    }
  }




  @include mui-animation(fade);
  //@include xy-grid();

  background-color: $white;

  h1, h2, h3, h4, .subheading {
    width: 100%;
    font-family: "MuseoSansRounded-300" !important;
  }
  .subheading {
    font-weight: bold;
  }
  h1, .subheading {
    color: $black;
    font-size: rem-calc(40);
  }
  h2 {
    color: black;
    font-size: rem-calc(40);
  }
  h3 {
    color: $dheading3;
  }
  h4 {
    color: $dheading3;
    font-size: rem-calc(20);
  }
  p, li {
    width: 100%;
    line-height: 150%;
    color: black;
    font-family: "MuseoSlab-300", serif;
    font-size: rem-calc(20);
  }
  a {
    color: #2980b9;
    &:hover {
      color: #1abc9c;
    }
  }
}

// to be applied to body with JS
.light {
  background-color: $lbackground;
  h3 {
    color: $lheading3;
  }
  p {
    color: $black;
  }
}

.content {

  #disqus_thread {
    width: 100% !important;
    position: relative;
  }
  @include xy-grid();
  .mleft {
    overflow: hidden;
    @include breakpoint(small only) {
      display: none;
    }
    @include breakpoint(medium) {
      top: 12.5%;
      bottom: 10%;
      min-height: 100vh;
      position: fixed;
      text-align: right;
      padding-right: rem-calc(20);
      @include xy-cell(3, $gutter-output:false);
      @include xy-grid(vertical);
      background-color: $white;
      #sidebar {
        a {
          color: black;
          &:hover {
            color: #1abc9c;
          }
        }
        height: 100%;
        padding-right: rem-calc(5);
        @include xy-cell(12, $vertical: true);
        @include xy-grid(vertical);
        .name {
          margin-bottom: rem-calc(20);
          //@include xy-cell(1, $vertical: true)
          position: relative;
          margin-right: -40px !important;
          //height: 3%;
          width: 100%;
          font-size: rem-calc(15);
          font-family: "MuseoSansRounded-500", serif;
          //font-weight: bold;
          position: relative;

        }
        .nav {
          padding-top: 0;
          margin-top: 0;
          padding-bottom: 0;
          margin-bottom: 0;
          height: 65%;
          width: 100%;
          ul {
            font-size: rem-calc(22);
            font-family: "MuseoSansRounded-300", serif;
            list-style: none;
            li {
              //padding-bottom: 32%;
            }
          }

        }
        .connect {
          p {
            font-weight: bold;
            font-size: rem-calc(13);
            margin-bottom: 0;
          }
          //@include xy-cell(2, $vertical: true)
          position: relative;
          //margin-right: -40px !important;
          //height: 3%;
          width: 100%;
          font-size: rem-calc(15);
          font-family: "MuseoSansRounded-500;", serif;
          //font-weight: bold;
          position: relative;
        }
        .cc {
          margin-top: 0;
          padding-top: 0;
          text-align: right;
          i {
            font-size: rem-calc(12);
          }
          font-family: "MuseoSlab-300", serif;
          font-style: italic;
          font-size: rem-calc(10);
          height: 10%;
          width: 100%;
          //position: relative;
          height: 100%;
          //top: 40;
          //@include xy-cell(1, $vertical: true)
        }
      }

      &:after {
        z-index: -1;
        position: fixed;
        top: 10%;
        width: inherit;
        position: fixed;
        display: inline;
        content: "";
        height: 80%;
        border-right: rem-calc(2.5) solid black;
      }
    }
    @include breakpoint (small) {
      @include xy-grid();
    }
  }
  .mright {
    li {
      margin-left: rem-calc(10);
      @include breakpoint(small) {
        font-size: rem-calc(18);
      }
    }
    @include breakpoint(medium) {
      .small-dropdown {
        display: none;
      }
      padding-bottom: rem-calc(120);
      position: absolute;
      @include xy-grid;
      @include breakpoint(medium) {
        padding-left: rem-calc(5);
        .title {
          display: table;
          height: rem-calc(210);
          @include xy-cell(8);
          border-bottom: rem-calc(2.5) solid black;
          #text {
            //text-align: justify;
            display: table-cell;
            vertical-align: bottom;
            h1 {
              bottom: 0;
            }
          }
        }
        .title-image {
          img {
            border: rem-calc(3) solid black;
            position: relative;
            margin-top: rem-calc(130);
            max-width: rem-calc(150);
            border-radius: 100%;
          }
        }

      }
    }
    .share {
      @include breakpoint(small only) {
        display: none;
      }
      padding-right: rem-calc(10);
      text-align: right;
      position: relative;
      @include xy-cell(8);
      //width: 100%;
      margin-top: rem-calc(-70);
      a {
        color: black;
        &:hover {
          color: #1abc9c;
        }
      }
    }

    .content {

      position: relative;
      margin-top: rem-calc(-30);
      @include breakpoint(large up) {
        @include xy-cell(9);
        padding-left: rem-calc(18);
      }
      @include breakpoint(medium only) {
        @include xy-cell(10);
      }
    }
    @include breakpoint(medium) {
      .small-top {
        display: none;
      }
    }
    @include breakpoint(small only) {
      //margin-top: rem-calc(70);
      .small-dropdown {
        .social-small {
          padding-bottom: rem-calc(20);
          display: none;
          @include xy-cell();
          text-align: center;
          a {
            color: black;
            &:hover {
              color: #1abc9c;
            }
          }
        }
        @include xy-grid();
        width: 100%;
        nav {
          display: none;
          width: 100%;
        }
        ul {
          list-style-type: none;
          margin-left:0;
          width: 100%;
        }
        li {
          a {
            color: black;
            &:hover {
              color: #1abc9c;
            }
          }
          font-size: rem-calc(22);
          font-family: "MuseoSansRounded-300", serif;
          width: 100%;
          @include xy-cell();
          text-align: center;
        }
      }
        .small-wrap {
          padding-right: -500;
          &:after {

            z-index: -1;
            position: absolute;
            //top: 10%;
            width: 80%;
            //position: fixed;
            display: inline;
            content: "";
            margin-left:  20%;
            margin-top: rem-calc(65);
            padding-left: rem-calc(100);
            border-bottom: rem-calc(2) solid black;
          }
          @include xy-grid();
          .small-image {
            padding-left: rem-calc(30);
            @include xy-cell(6);

            position: relative;
            img {
              position: relative;
              border: rem-calc(3) solid black;
              margin-bottom: rem-calc(30);
              //margin-top: rem-calc(30);
              max-width: rem-calc(130);
              border-radius: 100%;
            }
        }
      }
      p {
        line-height: 140%;
      }
      .title-image {
        display: none;
      }
      .small-top {
        @include xy-grid();
        width: 100%;
        .menu-button {
          .x {
            display: none;
            font-size: rem-calc(20);
            &:hover {
              color: #e74c3c;
            }
          }
          text-align: right;
          padding-top: rem-calc(15);
          @include xy-cell(12);
          width: 100%;
          .button-text {
            font-family: "MuseoSansRounded-300", serif;
            padding-right: rem-calc(10);
            //font-weight: bold;
          }
          .hamburger {
            margin-right: rem-calc(5);

            @include hamburger($color: black,$width: rem-calc(30), $height: 20px, $weight: 3px);
          }
        }
      }
      overflow: hidden;
      width: 100%;
      @include xy-grid();

      .title {
        margin-right: rem-calc(35)!important;
        h1 {
          font-size: rem-calc(34);
          padding-left: rem-calc(24);
        }
        @include xy-cell-block();
        width: 100%;
        border-bottom: rem-calc(2) solid black;
      }
      .content {
        p {
          font-size: rem-calc(18);
        }
        h2 {
          font-size: rem-calc(34);
        }
        position: relative;
        margin-top: rem-calc(25);
        @include xy-cell(11.5);
        padding-left: rem-calc(15);
        padding-bottom: rem-calc(50);
      }
      footer {
        padding-bottom: rem-calc(10);
        @include xy-cell();
        text-align: center;
        i {
          font-size: rem-calc(12);
        }
        font-family: "MuseoSlab-300", serif;
        font-style: italic;
        font-size: rem-calc(10);
      }
    }
    .cv_item{
      @include xy-cell(12);
      li {
        font-size: rem-calc(20);
      }
      @include breakpoint(small only){
        li {
          font-size: rem-calc(18);
        }
      }
    }
  }
}

.license {
  font-size: rem-calc(14);
  font-family: "MuseoSlab-300", serif;
  font-style: italic;
  text-align: center;
  width: 100%;
  hr {
    width: 40%;
  }
}

.spacer {
  @include xy-cell(full);
  padding-top: rem-calc(40);
}

.padding-fix {
  @include breakpoint(medium) {
    margin-right: rem-calc(110);
  }
  @include breakpoint(large) {
    margin-right: rem-calc(70);
  }
}

.left {
  @include xy-cell(4);
}

.right {
  @include xy-cell(8);
}


.gallery {
  padding-bottom: rem-calc(40);
  @include xy-grid();
  .gallery-cover {
    @include xy-cell(11);
    h2 {
      color: #2980b9;
    }
    &:hover {
      h2 {
        color: #1abc9c;
      }
      img {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,.4);
      }
    }
  }
  img {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
  }

  .thumb {
    @include mui-fade(in, $duration: 5s);
    width: 100%;
    @include breakpoint(small only) {
      @include xy-cell(12);
    }
    @include breakpoint(medium up) {
      @include xy-cell(6);
      @include flex;
      @include flex-align(center, middle);
      margin-left: 0;
    }
    &:hover {
      img {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,.4);
      }
    }
  }
}

figure {
  img {
    padding: rem-calc(4);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.4);
  }
}

.sample {
   img, iframe {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,.4);
   }
}

hr {
   display: block;
   position: relative;
   padding: 0;
   margin: 8px auto;
   height: 0;
   width: 87%;
   max-height: 0;
   font-size: 1px;
   line-height: 0;
   clear: both;
   border: none;
   border-top: 1px solid #aaaaaa;
   border-bottom: 1px solid #ffffff;
}

.checklist {
  list-style-type: none;
}
