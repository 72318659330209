// --------------------------------------------------
// Layout page
// --------------------------------------------------

main {
@include xy-grid();
  .mleft {
  	content: '';
    @include xy-cell(3.5, $gutter-output: false);
    background-color: $link;

    text-align: right;
  }
  .mright {
    @include xy-cell(8.5);
  }

  //other elements
  //@include grid-column;
}